import React, { useContext, useEffect, useState, useCallback } from "react";
import TextPage from "../../components/TextPage";
import { useParams } from "react-router-dom";
import { getPostByLink } from "../../services/blog";
import { AppContext } from "../../context";
import { Loader, LoaderContainer } from "./styles";

const Blog = () => {
  const params = useParams();
  const { isQaEnv } = useContext(AppContext);

  const [postInfo, setPostInfo] = useState({ title: "", content: "" });
  const [isLoading, setIsLoading] = useState(true);

  const getBlogInfo = useCallback(async () => {
    const link = params.link;

    setIsLoading(true);
    const result = await getPostByLink(link, isQaEnv);

    if (!result.success) {
      setPostInfo({
        title: "Not Found",
      });
      setIsLoading(false);
      return;
    }

    const { title, text } = result.post;
    setPostInfo({ title, content: text });
    setIsLoading(false);
  }, [params.link, isQaEnv]);

  useEffect(() => {
    getBlogInfo();
  }, [getBlogInfo]);

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader>
          <img src="/images/bouncing-logo.svg" alt="bouncing-logo" />
        </Loader>
      </LoaderContainer>
    );
  }

  return <TextPage {...postInfo} isLoading={isLoading} />;
};

export default Blog;
