import React from "react";
import { useNavigate } from "react-router-dom";
import { Wrapper } from "../../pages/HomeLanding/styles";
import Header from "../../pages/HomeLanding/Header";
import Footer from "../../pages/HomeLanding/Footer";
import {
  BackToHomeBtn,
  BackToHomeContainer,
  HeaderContainer,
  HeroContent,
  HeroLastUpd,
  HeroSection,
  HeroTitle,
  MainContent,
  MainSection,
} from "./styles";

const TextPage = ({ title, content, contentElement, lastUpdated }) => {
  const navigate = useNavigate();

  const handleClickBackToHome = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/");
  };

  return (
    <Wrapper>
      <BackToHomeContainer>
        <BackToHomeBtn onClick={handleClickBackToHome}>
          <img src="/images/back.svg" alt="back" />
          Back to home
        </BackToHomeBtn>
      </BackToHomeContainer>
      <HeaderContainer>
        <Header noTab />
      </HeaderContainer>
      <HeroSection>
        <HeroContent>
          <HeroTitle>{title}</HeroTitle>
          {!!lastUpdated && (
            <HeroLastUpd>
              Last updated on <span>{lastUpdated}</span>
            </HeroLastUpd>
          )}
        </HeroContent>
      </HeroSection>
      <MainSection>
        {contentElement ? (
          <MainContent>{contentElement}</MainContent>
        ) : (
          <MainContent dangerouslySetInnerHTML={{ __html: content }} />
        )}
      </MainSection>
      <Footer forPrivacyAndTerms />
    </Wrapper>
  );
};

export default TextPage;
