import styled from "styled-components";
import MainIntroVideo from "./MainIntroVideo/index";

const Preview = ({
  isMobile,
  isDescriptionVisible,
  children,
  videoThumbnailSrc,
  isHomePage,
}) => {
  return (
    <PreviewContainer $isMobile={isMobile}>
      <PreviewContent>
        <Title $isDescriptionVisible={isDescriptionVisible}>
          {!isHomePage && "100% Personal Touch, 0% Human Effort"}
        </Title>
        {isDescriptionVisible && (
          <Description>
            {!isHomePage &&
              "Reach helps you write thousands of personally-tailored messages, in seconds."}
          </Description>
        )}
        {children}
      </PreviewContent>
      <VideoContainer>
        <MainIntroVideo
          videoUrl="video/intro_video5.webm"
          altVideoUrl="video/intro_video5.mp4"
          videoThumbnailSrc={videoThumbnailSrc}
        />
      </VideoContainer>
    </PreviewContainer>
  );
};

export default Preview;

const PreviewContainer = styled.div`
  background-image: url("images/gradient-background.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: flex;
  flex-direction: ${({ $isMobile }) => (!$isMobile ? "row-reverse" : "column")};
  align-items: center;
  padding: 0 20px;

  @media (max-width: 768px) {
    background-image: url("images/gradient-background-mobile.png");
  }
`;

const PreviewContent = styled.div`
  padding: 5vw calc((100vw - 1500px) / 2);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 5vw calc((100vw - 320px) / 2);
  }
`;

const Title = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 800;
  font-size: ${({ theme }) => theme.fontSizes.xxxLarge};
  letter-spacing: -0.03em;
  margin-bottom: ${({ $isDescriptionVisible }) =>
    $isDescriptionVisible ? "0" : "3vw"};

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.xLarge};
  }
`;

const Description = styled.div`
  text-align: center;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.semiWhite};
  font-size: ${({ theme }) => theme.fontSizes.text};
  line-height: 27px;
  margin-bottom: 3vw;
  padding: 0 60px;

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.small};
    line-height: 19.5px;
    padding: 0 5px;
  }
`;

const VideoContainer = styled.div`
  width: 60vw;
  flex-grow: 1;
  flex-shrink: 1;

  @media (max-width: 768px) {
    width: 80vw;
  }
`;
