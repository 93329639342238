import { fetchPostByLink } from "./queries";

const getPostByLink = async (data, isQaEnv) => {
  const result = await fetchPostByLink(data, isQaEnv);

  if (result.message) {
    return { success: false, message: result.message };
  }

  return { ...result, success: true };
};

export { getPostByLink };
