import React, { useRef } from "react";

import Header from "./Header";
import Benefits from "./Benefits";
import FeaturesContainer from "./Features";
import IntroVideo from "./IntroVideo";
import Subscribe from "./Subscribe";
import Footer from "./Footer";
import TestimonialsContainer from "./Testimonials";
import Recommendation from "./Recommendation";
import SuggestionPopup from "../../components/SuggestionPopup";
import { CHROME_STORE_EXTENSION_URL } from "../../utils/constants";

import {
  BenefitsContainer,
  RecommendationContainer,
  HeaderContainer,
  MainContent,
  VideoContainer,
  Wrapper,
  SquiggleImage,
} from "./styles";

function HomeLanding({ isExtensionInstalled }) {
  const menuRefs = useRef({
    aboutReach: null,
    features: null,
    testimonials: null,
    whyReach: null,
  });

  return (
    <Wrapper>
      {isExtensionInstalled === false && (
        <SuggestionPopup
          imageSrc={"/images/sad-computer.png"}
          imageAlt={"sad computer"}
          title={"We can’t find the Reach extension"}
          description={PopupDescriptionText()}
          styles={{
            titleStyles: {
              fontColor: "#ff0000",
            },
          }}
        />
      )}
      <HeaderContainer>
        <Header sectionRefs={menuRefs} />
      </HeaderContainer>
      <BenefitsContainer ref={(el) => (menuRefs.current.aboutReach = el)}>
        <Benefits />
        <SquiggleImage src="images/curve-line.png" />
      </BenefitsContainer>
      <MainContent>
        <VideoContainer>
          <IntroVideo
            videoUrl="video/intro_video5.webm"
            altVideoUrl="video/intro_video5.mp4"
            videoThumbnailSrc="/images/video-thumbnail-3.png"
          />
        </VideoContainer>
        <FeaturesContainer ref={(el) => (menuRefs.current.features = el)} />
        <TestimonialsContainer
          ref={(el) => (menuRefs.current.testimonials = el)}
        />
        <RecommendationContainer ref={(el) => (menuRefs.current.whyReach = el)}>
          <Recommendation />
        </RecommendationContainer>
      </MainContent>
      <Subscribe />
      <Footer />
    </Wrapper>
  );
}

const PopupDescriptionText = () => {
  return (
    <>
      It looks like you clicked a link that should open in Reach but Reach isn’t
      installed in this browser profile. Please switch profiles or reinstall Reach by clicking{" "}
      <a href={CHROME_STORE_EXTENSION_URL}>here</a>
    </>
  );
};

export default HomeLanding;
