import React, { useEffect } from "react";
import {
  PRIVACY_POLICY_CONTENT,
  TERMS_CONDITIONS_CONTENT,
} from "../../utils/constants";
import TextPage from "../../components/TextPage";
import PageContent from "./PageContent";

const PrivacyAndTerms = ({ isTermsOfUse }) => {
  const title = isTermsOfUse
    ? "Terms of Use"
    : "Privacy, Cookies & Confidentiality";
  const content = isTermsOfUse ? (
    <PageContent content={TERMS_CONDITIONS_CONTENT} />
  ) : (
    <PageContent content={PRIVACY_POLICY_CONTENT} />
  );
  const lastUpdated = isTermsOfUse ? null : "Nov 1 2024";

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return;
  }, [isTermsOfUse]);

  return (
    <TextPage
      title={title}
      contentElement={content}
      lastUpdated={lastUpdated}
    />
  );
};

export default PrivacyAndTerms;
